<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

        
    <v-card class="card">
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-card-title class="heading justify-center"> Return Book</v-card-title>

      <div class="">
        <br />
        <v-row justify="center" align="center">
          <!-- <v-col cols="12" md="1" sm="1"></v-col> -->

          <v-col sm="3">
            <label style="font-weight: bold; color: blue">Select Accesstion No</label>
            <v-combobox class="" @click="isbookselected = false" @change="set1(accessionNo, 'book')"
              placeholder="Select Accesstion No" v-model="accessionNo" :items="accessionNoList" item-text="bookName"
              outlined return-object dense :rules="[rules.required]" clearable required></v-combobox>
          </v-col>
          <v-col sm="3">
            <label style="font-weight: bold; color: blue">For Barcode Purpose</label><v-text-field outlined
              v-model="accno" dense class="text" placeholder="Enter Accession No"
              @input="set1('', 'bookbarcode')"></v-text-field>
          </v-col>
          <v-col sm="3">
            <v-checkbox class="pr-3" v-model="isbackdated" label="Is back dated entry " dense hide-details
              @click="allowbackdate()"></v-checkbox>
            <v-menu v-if="backdated == true" v-model="showPicker6" :close-on-content-click="false"
              transition="scale-transition" offset-y dense class="text" full-width max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="issue_backdate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense
                  class="text" readonly outlined v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="issue_backdate" no-title @input="showPicker6 = false"></v-date-picker>
            </v-menu>
            <v-menu v-else v-model="showPicker6" :close-on-content-click="false" transition="scale-transition" offset-y
              dense full-width max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="issue_backdate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense
                  readonly outlined v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="issue_backdate" no-title disabled @input="showPicker6 = false"></v-date-picker>
            </v-menu>

          </v-col>

          <v-col cols="12" md="2" sm="2">
            <center>
              <v-btn :disabled='!isbookselected' class="primary ma-2" @click="findBookInfo(accessionNo)">Fetch
                Details</v-btn>
            </center>
          </v-col>
        </v-row>
        </div>
        <div v-if="userInfo">
          <div class="cus-card">
            <v-row>
              <v-col lg="2"> <label class="c-label">Title</label></v-col>
              <v-col><span>: {{ book_info.title }}</span></v-col>
              <v-col lg="3"> <label class="c-label">Author(s)</label></v-col>
              <v-col><span v-for="(item, index) in book_info.authors" :key="index">: {{ item }}</span></v-col>
            </v-row>
            <v-row>
              <v-col lg="2"> <label class="c-label">Category</label></v-col>
              <v-col><span>: {{ book_info.category }}</span></v-col>
              <v-col lg="3"> <label class="c-label">Publisher</label></v-col>
              <v-col><span>: {{ book_info.publisher }}</span></v-col>
            </v-row>
            <v-row>
              <v-col lg="2"> <label class="c-label">Book Type</label></v-col>
              <v-col><span>: {{ book_info.bookType }}</span></v-col>
              <v-col lg="3"> <label class="c-label">Book Format</label></v-col>
              <v-col><span>: {{ book_info.bookFormat }}</span></v-col>
            </v-row>
            <v-row>
              <v-col lg="2"> <label class="c-label">Book Price</label></v-col>
              <v-col><span>: {{ book_info.price }}</span></v-col>
              <v-col lg="3">
                <label class="c-label">No of Copies available</label></v-col>
              <v-col><span>: {{ book_info.copiesAvailable }}</span></v-col>
            </v-row>
          </div>
          <v-simple-table class="m-3" style="border: 1px solid">
            <template v-slot:default>
              <thead>
                <tr style="background: lightgray">
                  <th class="text-left" style="font-size: 16px">EMP_ID/PRN</th>
                  <th class="text-left" style="font-size: 16px">NAME</th>
                  <th class="text-left" style="font-size: 16px">Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ book_info.grno_empid }}</td>
                  <td>{{ book_info.name }}</td>
                  <td>{{ book_info.username }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="d-flex justify-content-around">
            <div class="p-0">
              <label>Borrowed Date</label>
              <v-text-field outlined dense readonly v-model="book_info.borrowed_date"></v-text-field>
            </div>
            <div class="p-0">
              <label>Due Date</label>
              <v-text-field outlined dense readonly v-model="book_info.dueDate"></v-text-field>
            </div>
          </div>
          <center>
            <v-col v-if="book_info.fine !== 'NA'" cols="4" class="p-0">
              <label>Fine</label>
              <v-text-field outlined dense v-model="main_amt"></v-text-field>
              <v-container class="px-0" fluid>
                <v-row>
                  <v-checkbox v-model="fine_settle" class="mr-5" :label="`Fine Settlement`"></v-checkbox>

                  <v-checkbox v-model="clear_all_fine" :label="`Clear All Fine`"></v-checkbox></v-row>
              </v-container>

              <div>
                <v-btn small class="btn mb-5" color="error" @click="payFineData">Pay Fine</v-btn>
              </div>
            </v-col>

            <v-simple-table v-if="trackdata" class="m-3" style="border: 1px solid">
              <template v-slot:default>
                <thead>
                  <tr style="background: lightgray">
                    <th class="text-left" style="font-size: 16px">
                      Book Title
                    </th>
                    <th class="text-left" style="font-size: 16px">
                      Accession Number
                    </th>
                    <th class="text-left" style="font-size: 16px">Paid Fine</th>
                    <th class="text-left" style="font-size: 16px">
                      Remaning Fine
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ trackdata.title }}</td>
                    <td>{{ trackdata.acc_no }}</td>
                    <td style="color: green; font-weight: bold; font-size: 18px">
                      {{ trackdata.paid_amt }}
                    </td>
                    <td style="color: red; font-weight: bold; font-size: 18px">
                      {{ trackdata.unpaid_amt }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <v-btn class="btn mb-2" :disabled="returnBookBtn" color="info" @click="receiveBook">Receive Book</v-btn>
            </div>
          </center>
        </div>
      </div>
    </v-card>

    <!-- <template> -->
    <v-row justify="center">
      <v-dialog persistent v-model="payFineDialoge" fullscreen hide-init_loading transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="info">
            <v-toolbar-title>Pay Fine</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="payFineDialoge = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row class="justify-content-center mt-3" v-if="!paymentSuccess">
            <v-col cols="8" class="payfine" v-if="memberData">
              <div style="border: 2px solid cadetblue">
                <v-row style="margin: 0px 10px 0px 10px">
                  <v-col><span>Member Name :</span><span class="mem_info">{{ memberData.memberName }}</span>
                  </v-col>
                  <v-col><span>Book Title :</span><span class="mem_info">{{ memberData.title }}</span></v-col>
                  <v-col><span>Member Id :</span><span class="mem_info">{{
                    memberData.memberCode
                  }}</span></v-col>
                </v-row>
                <v-row style="margin: 0px 10px 0px 10px">
                  <v-col><span>Check-Out Date :</span><span class="mem_info">{{
                    memberData.checkOut_date
                  }}</span>
                  </v-col>
                  <v-col><span>Due Date :</span><span class="mem_info">{{
                    memberData.due_date
                  }}</span></v-col>
                  <v-col><span>Returned Date :</span><span class="mem_info">{{
                    memberData.return_date
                  }}</span></v-col>
                </v-row>
              </div>

              <div>
                <v-row class="mt-3 justify-content-center">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Fine</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-text-field v-model="main_amt" class="text" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center mt-0">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Mode Of Payments</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-radio-group v-model="pay_method" row>
                      <v-radio v-for="(item, id) in transactionType_data" :key="id" :label="item.name"
                        :value="item.id"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Transaction No</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-text-field v-model="transactioN" class="text" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Bank Name</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-text-field v-model="bankName" class="text" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Bank Branch</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-text-field v-model="branchName" class="text" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center">
                  <v-col cols="3" class="p-0">
                    <v-subheader class="title1">Remark</v-subheader>
                  </v-col>
                  <v-col cols="5" class="p-0">
                    <v-text-field v-model="remark" class="text" outlined dense></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="justify-content-center">
                  <v-btn color="info" @click="payfine">Pay Fine</v-btn>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-row class="justify-content-center mt-3" v-if="paymentSuccess">
            <v-col cols="8" class="payfine" v-if="memberData">
              <p class="fine">Fine submitted Successfully!!</p>
              <center>
                <v-btn color="info" @click="downloadReceipt"><v-icon left dark>mdi-download</v-icon> Download
                  Receipt</v-btn>
              </center>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>


<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    valid: false,
    init_loading: false,
    userid: "",
    bookitemid: "",
    fine_settle: false,
    clear_all_fine: false,
    main_amt: null,
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    isbackdated: false,
    backdated: false,
    issue_backdate: "",
    snackbar_msg: "",
    transactionType_data: null,
    color: "",
    snackbar: false,
    search: "",
    pay_method: null,
    accessionNoList: [],
    paymentSuccess: false,
    book_info: [],
    bookinformation: [],
    empmemberbooklendinginfo: [],
    allBookInfo: [],
    payFineDialoge: false,
    accessionNo: "",
    accessionNo1: "",
    selectedbookitem: "",
    fine_radio: false,
    org: "",
    inSave: true,
    dialog: false,
    bookCategoryList: [],
    roleLink_list: [],
    role_list: [],
    userInfo: false,
    bookInfo: false,
    bookLId: null,
    memberData: null,
    transactioNo: null,
    bankName: null,
    branchName: null,
    paymentMode: null,
    remark: null,
    returnBookBtn: false,
    isempselected: true,
    isbookselected: false,
    accno: "",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Return Book',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Book" : "Edit Book";
    },
  },
  watch: {
    payFineDialoge() {
      if (!this.payFineDialoge) {
        this.paymentSuccess = false;
      }
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    set1(value, person) {
      if(person=='barcode')
      {
        this.accno = value.bookName.split(":")[1].trim();
        this.isbookselected = true;
      }
      else{
       
        this.isbookselected = true;
      }
    
    },
    allowbackdate() {
      //  alert("in allow date"+ this.isbackdated);
      if (this.isbackdated == true) {
        this.backdated = true
      } else {
        this.issue_backdate = "";
      }
    },
    payFineData() {
      const data = {
        bookLId: this.bookLId,
      };
      if (this.fine_settle && this.clear_all_fine) {
        this.showSnackbar(
          "#b71c1c",
          "Select only one from file 'Fine Settlement' and 'Clear All Fine'"
        );
        this.fine_settle = false;
        this.clear_all_fine = false;
      } else {
        this.payFineDialoge = true;

        axios
          .post("/ReturnBook/payFineData", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.memberData = res.data.data;
              this.transactionType_data = res.data.transactionType_data;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.init_loading = false;
          })
          .finally(() => {
            // va
          });
      }
    },

    payfine() {
      this.payFineDialoge = true;

      if (this.pay_method == null || this.book_info.fine == null) {
        this.showSnackbar("#b71c1c", "Payment method and fine required!! ");
      } else {
        const data = {
          amount: this.book_info.fine,
          paid_amount: this.main_amt,
          pay_method: this.pay_method,
          transactioNo: this.transactioNo,
          bankName: this.bankName,
          branchName: this.branchName,
          bookLId: this.bookLId,
          remark: this.remark,
          fine_settle: this.fine_settle,
          clear_all_fine: this.clear_all_fine,
        };
        console.log("data---------", data);
        axios
          .post("/ReturnBook/payfine", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.returnBookBtn = false;
              this.book_info.fine = "NA";
              this.paymentSuccess = true;
              this.trackdata = res.data.trackdata;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.init_loading = false;
          })
          .finally(() => {
            // va
          });
      }
    },

    findBookInfo(a_no) {
      console.log(a_no);
      console.log("date");
      console.log(this.issue_backdate);
      this.init_loading = true;
      this.accessionNo1 = this.accno;
      const data={
          a_no : this.accno,
           backdate : this.issue_backdate,
      };

      axios
        .post("/ReturnBook/getBookData", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.userInfo = true;
            this.book_info = res.data.book_info;
            this.main_amt = this.book_info.fine;
            this.bookLId = res.data.book_info.bookLId;
            if (this.book_info.fine == "NA") {
              this.returnBookBtn = false;
            } else {
              this.returnBookBtn = true;
            }
            // this.empmemberbooklendinginfo = res.data.booklendinginformation;
            // this.onLoad()
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          // var init_loading = false;
        });
    },

    receiveBook() {
      const data = {
        a_no: this.accessionNo1,
        backdate: this.issue_backdate,
      };
      this.init_loading = true;
      axios
        .post("/ReturnBook/receiveBook", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Return Book Successfully");
            this.init_loading = false;
            this.onLoad();
            this.accessionNo = null;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => { });
    },
    onLoad() {
      this.init_loading = true;
      axios
        .get("/ReturnBook/getAccessionList")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.accessionNoList = res.data.accessionNoList;
            this.userInfo = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          // var init_loading = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      this.editedItem.displayOrder = parseInt(this.editedItem.displayOrder);
      this.editedItem.capacity = parseInt(this.editedItem.capacity);
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          axios
            .post("/Admin/editRack", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //window.alert(res.data.msg)
                Object.assign(
                  this.roleLink_list[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar("#4caf50", "Rack Updated Successfully..."); // show snackbar on success
                this.onLoad();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        // this.roleLink_list.push(this.editedItem)
        axios
          .post("/Admin/saveRack", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.showSnackbar("#4caf50", "Rack Added Successfully!!!"); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, //save()

    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.payfine {
  background: lightcyan;
  padding: 1rem;
}

.mem_info {
  font-weight: bold;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.title1 {
  font-weight: bold;
  font-size: 15px;
}

.v-text-field>>>.v-subheader {
  font-size: 5px;
}

.fine {
  font-size: 25px;
  font-family: cursive;
  font-weight: bold;
  text-align: center;
}
</style>
